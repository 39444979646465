<template>
  <button
    class="btn btn_filled"
    :type="type"
    :disabled="disabled"
  >
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'SettingsProfileButton',

  props: {
    type: String,
    title: String,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  &_reset:not([disabled]) {
    color: $lightThemeText;
    background: #e8e8f2;

    &:hover {
      background: #dfdfe9;
    }

    &:active {
      background: #d5d5df;
    }
  }
}
</style>
